import React from "react";

import "./eq.css"


const EQ = () => {

  return(
  <div className="equaliser-container">
    <ol className="equaliser-column">
      <li className="colour-bar"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
    </ol>
    <ol className="equaliser-column">
      <li className="colour-bar"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
    </ol>
    <ol className="equaliser-column">
      <li className="colour-bar"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
    </ol>
    <ol className="equaliser-column">
      <li className="colour-bar"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
    </ol>
    <ol className="equaliser-column">
      <li className="colour-bar"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
      <li className="led-mask"></li>
    </ol>
  </div>
  )
}

export default EQ