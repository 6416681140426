export const radio_search_filters = 
  [
    "Pop",
    "Rock",
    "Metal",
    "Dance",
    "Jazz",
    "Electro",
    "Blues",
    "Funk",
    "Alternative",
    "Classical"
  ]

export const spotify_response_filters =
  [
    "artists",
    "albums",
    "tracks"
  ]  
