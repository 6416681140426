import React from "react";

import "./joints.css"


const Joint800px = () => {
  return(
    <div className="joint800">
      
    </div>
  )
}

export default Joint800px